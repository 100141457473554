import { css } from "@emotion/react";

import { Grid, SwipeableDrawer, styled } from "@mui/material";

import { useLocale } from "@/custom-hooks/common/useLocale";
import { appColor } from "@/styles/app-colors";

import { DashboardSettingItem } from "../DashboardSettingItem";

import { SVGIconCloseButton, SVGIconLogoColor } from "@/svgs";

const CustomSwiperbleDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: "100%",
    height: "100%",
    position: "absolute",
  },
}));

type Props = {
  anchor?: "top" | "right" | "bottom" | "left";
  open: boolean;
  onClose: () => void;
  onOpen: (e: React.MouseEvent<HTMLElement>) => void;
  swipeAreaWidth?: number;
  disableSwipeToOpen: boolean;
  modalProps: boolean;
};

export const DashboardDrawer: React.FC<Props> = (props) => {
  const { translated } = useLocale();
  return (
    <CustomSwiperbleDrawer open={props.open} onClose={props.onClose} onOpen={props.onOpen} anchor="right">
      <Grid css={headerStyled}>
        <Grid>
          <SVGIconLogoColor />
        </Grid>
        <Grid css={cancelIconBoxStyled} onClick={props.onClose}>
          <SVGIconCloseButton />
        </Grid>
      </Grid>
      <Grid>
        <DashboardSettingItem
          ticketText={translated.COMMON.RESERVED_TICKETS}
          mailaddressText={translated.COMMON.ADDRESS_CHANGE}
          passwordText={translated.COMMON.PASSWORD_CHANGE}
          logoutText={translated.COMMON.LOGOUT}
          onClose={props.onClose}
        />
      </Grid>
    </CustomSwiperbleDrawer>
  );
};

const headerStyled = css`
  position: relative;
  display: flex;
  width: 100%;
  place-items: center;
  height: 80px;
  padding: 0px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid ${appColor.Border.grayLight};
`;

const cancelIconBoxStyled = css`
  display: flex;
  height: 40px;
  padding: 5px 12px;
  align-items: center;
  gap: 12px;
  border-radius: 20px;
  border: 1px solid ${appColor.Border.grayLight};
`;
