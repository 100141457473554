import { css } from "@emotion/react";
import * as React from "react";

import { Menu } from "@mui/material";

import { useLocale } from "@/custom-hooks/common/useLocale";

import { DashboardSettingItem } from "../../../DashboardSettingItem";

type Props = {
  onClose: () => void;
  open: boolean;
  anchorEl: HTMLElement | null;
};

export const DashboardTopMenu: React.FC<Props> = (props) => {
  const { translated } = useLocale();

  return (
    <Menu
      css={rootStyled}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorEl={props.anchorEl}
      open={props.open}
      onClose={props.onClose}
    >
      <DashboardSettingItem
        ticketText={translated.COMMON.RESERVED_TICKETS}
        mailaddressText={translated.COMMON.ADDRESS_CHANGE}
        passwordText={translated.COMMON.PASSWORD_CHANGE}
        logoutText={translated.COMMON.LOGOUT}
        onClose={props.onClose}
      />
    </Menu>
  );
};

const rootStyled = css`
  & .MuiList-root.MuiMenu-list {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`;
