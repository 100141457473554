import { css } from "@emotion/react";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";

import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";

import { useLocale } from "@/custom-hooks/common/useLocale";
import { appColor } from "@/styles/app-colors";
import { appTypo } from "@/styles/app-typography";
import { mqMinScreen } from "@/utils/mediaQuery";

import LangSettingDialog from "../../dialog/LangSettingDialog";
import { DashboardDrawer } from "../../drawer/DashboardDrawer";
import { LangSettingDrawer } from "../../drawer/LangSettingDrawer";
import { DashboardTopMenu } from "./Menu/DashboardTopMenu";

import { authPath, routePath, signUpPath } from "@/constants/app-paths";
import { LocalForageKeys } from "@/constants/local-forage-keys";
import { authTokenInfoAtom, headerHeightAtom, loadingAtom } from "@/global-states/common-atoms";
import { SVGIconDashboard, SVGIconLogo, SvgIconLangSetting } from "@/svgs";
import { useAtom } from "jotai";
import localforage from "localforage";

const drawerBleeding = 56;

// TODO lang setting
const Header = () => {
  const { locale, translated } = useLocale();
  const router = useRouter();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));
  const [isOpenLangSettingDialog, setIsOpenLangSettingDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onClickOpenDashboardSetting = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const onClickCloseDashboardSetting = () => {
    setAnchorEl(null);
  };
  const [authTokenInfo] = useAtom(authTokenInfoAtom);
  const [loading] = useAtom(loadingAtom);

  const onClickRedirectHome = async () => {
    await router.push(routePath);
  };

  const onClickLogin = async () => {
    await localforage.setItem(LocalForageKeys.auth.urlRedirectToAfterLogin, router.asPath);
    await router.push(authPath);
  };

  const onClickSignUp = async () => {
    await localforage.setItem(LocalForageKeys.auth.urlRedirectToAfterLogin, router.asPath);
    await router.push(signUpPath);
  };

  const onClickOpenLangSetting = () => {
    setIsOpenLangSettingDialog(true);
  };

  const onClickCloseLangSetting = () => {
    setIsOpenLangSettingDialog(false);
  };

  const [headerHeight, setHeaderHeight] = useAtom(headerHeightAtom);
  const headerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  }, [headerRef.current]);

  return (
    <>
      <Grid css={fixStyled}>
        <Grid css={containerStyled} ref={headerRef}>
          <Grid css={innerStyled}>
            <Grid css={iconLogoBox} onClick={onClickRedirectHome}>
              <SVGIconLogo />
              {!isMobile && (
                <Grid css={siteNoteContainerStyled}>
                  <Box component="span" css={siteNoteStyled}>
                    {translated.COMMON.SITE_NOTE1}
                  </Box>{" "}
                  <Box component="span" css={siteNoteStyled}>
                    {translated.COMMON.SITE_NOTE2}
                  </Box>
                </Grid>
              )}
            </Grid>
            {!loading && (
              <Grid css={headerRightBlockStyled}>
                {isMobile && (
                  <Grid css={siteNoteContainerStyled}>
                    <Box component="span" css={siteNoteStyled}>
                      {translated.COMMON.SITE_NOTE1}
                    </Box>{" "}
                    <Box component="span" css={siteNoteStyled}>
                      {translated.COMMON.SITE_NOTE2}
                    </Box>
                  </Grid>
                )}
                <Grid css={authBox}>
                  <Grid css={langSettingIconBox} onClick={onClickOpenLangSetting}>
                    <SvgIconLangSetting />
                  </Grid>
                  {authTokenInfo?.accessToken && (
                    <Grid css={dashboardIconBox}>
                      <Grid css={dashboardIconStyle} onClick={onClickOpenDashboardSetting}>
                        <SVGIconDashboard />
                      </Grid>
                    </Grid>
                  )}
                  {!loading && !authTokenInfo?.accessToken && (
                    <>
                      {isMobile ? (
                        <>
                          <Grid css={authBoxContents}>
                            <Grid css={registrationBox}>
                              <Grid css={registrationForm} onClick={onClickSignUp}>
                                {translated.AUTH.SIGN_UP_TOPPAGE}
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid css={authBoxContents}>
                            <Grid css={loginLink} onClick={onClickLogin}>
                              {translated.COMMON.SIGN_IN}
                            </Grid>
                            <Grid css={registrationBox}>
                              <Grid css={registrationForm} onClick={onClickSignUp}>
                                {translated.AUTH.SIGN_UP_TOPPAGE}
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid css={borderStyled}></Grid>
        {isMobile ? (
          <LangSettingDrawer
            open={isOpenLangSettingDialog}
            onClose={onClickCloseLangSetting}
            onOpen={onClickOpenLangSetting}
            disableSwipeToOpen={true}
            modalProps
          />
        ) : (
          <LangSettingDialog isOpenLangSettingDialog={isOpenLangSettingDialog} onClickClose={onClickCloseLangSetting} />
        )}
        {isMobile ? (
          <DashboardDrawer
            open={open}
            onClose={onClickCloseDashboardSetting}
            onOpen={onClickOpenDashboardSetting}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={false}
            modalProps
          />
        ) : (
          <DashboardTopMenu anchorEl={anchorEl} open={open} onClose={onClickCloseDashboardSetting} />
        )}
      </Grid>
      <Grid css={containerHeightBoxStyled(headerHeight)}></Grid>
    </>
  );
};

export default Header;

const fixStyled = css`
  position: fixed;
  z-index: 5;
`;

const containerStyled = css`
  display: flex;
  width: 100vw;
  min-height: 80px;
  align-items: center;
  justify-content: center;
  background-color: ${appColor.Background.white}CC;
`;

const containerHeightBoxStyled = (headerHeight: number) => css`
  display: flex;
  width: 100vw;
  height: ${headerHeight}px;
`;

const innerStyled = css`
  display: flex;
  max-width: 1280px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  ${mqMinScreen("lg")} {
    padding: 0px 16px;
  }
`;

const iconLogoBox = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 16px;
`;

const headerRightBlockStyled = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
  padding: 8px 0;
`;

const siteNoteContainerStyled = css`
  text-align: end;
  ${mqMinScreen("sm")} {
    text-align: start;
  }
`;

const siteNoteStyled = css`
  display: inline-block;
  font-size: 14px;
  ${mqMinScreen("sm")} {
    font-size: 16px;
  }
`;

const authBox = css`
  display: flex;
  height: 40px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

const authBoxContents = css`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const loginLink = css`
  color: ${appColor.Text.black};
  font-family: ${appTypo.font.kintoSans};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  text-decoration-line: underline;
  cursor: pointer;
`;

const registrationBox = css`
  display: flex;
  height: 40px;
  padding: 5px 12px;
  align-items: center;
  gap: 12px;
  border-radius: 20px;
  border: 1px solid ${appColor.Border.primary};
  cursor: pointer;
`;

const registrationForm = css`
  color: ${appColor.Text.black};
  font-family: ${appTypo.font.kintoSans};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
  letter-spacing: 0.7px;
`;

const langSettingIconBox = css`
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const dashboardIconBox = css`
  display: flex;
  height: 40px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

const dashboardIconStyle = css`
  display: flex;
  height: 40px;
  padding: 5px 12px;
  align-items: center;
  gap: 12px;
  border-radius: 20px;
  border: 1px solid ${appColor.Border.grayLight};
  cursor: pointer;
`;

const borderStyled = css`
  width: 100vw;
  height: 1px;
  background-color: ${appColor.Border.gray};
  opacity: 0.08;
`;
