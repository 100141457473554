import { useRouter } from "next/router";
import { useState } from "react";

import { useAuth } from "@/custom-hooks/apis-common/use-auth";
import { useLocale } from "@/custom-hooks/common/useLocale";

import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";

export const useLogout = () => {
  const { translated } = useLocale();
  const { authLogout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit } = useForm();

  const [loading, setLoading] = useState(false);
  const router = useRouter();

  const onSubmitLogout = handleSubmit(async (values) => {
    setLoading(true);

    // ログアウト処理
    try {
      await authLogout();

      // 成功したらトップ画面に遷移する
      enqueueSnackbar(translated.AUTH.SIGN_OUT_SUBMIT, {
        variant: "default",
      });
      setLoading(false);
    } catch (err) {
      if (err instanceof Error) {
        enqueueSnackbar(translated.AUTH.SIGN_OUT_SUBMIT_ERROR, {
          variant: "error",
        });
      }
      setLoading(false);
    }
  });

  return {
    loading,
    handleSubmit,
    onSubmitLogout,
  };
};
