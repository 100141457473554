import { css } from "@emotion/react";
import { useRouter } from "next/router";

import { Grid, MenuItem } from "@mui/material";

import { useLogout } from "@/custom-hooks/auth/useLogout";
import { appColor } from "@/styles/app-colors";
import { appTypo } from "@/styles/app-typography";

import { changeMailAddressPath, changePasswordPath, purchaseListPath } from "@/constants/app-paths";

type Props = {
  ticketText: string;
  mailaddressText: string;
  passwordText: string;
  logoutText: string;
  onClose: () => void;
};

export const DashboardSettingItem: React.FC<Props> = (props) => {
  const router = useRouter();
  const { onSubmitLogout } = useLogout();
  const onClickTicket = async () => {
    await router.push(purchaseListPath);
  };
  const onClickMailaddress = async () => {
    await router.push(changeMailAddressPath);
  };
  const onClickPassword = async () => {
    await router.push(changePasswordPath);
  };
  const onClickLogout = async () => {
    props.onClose();
    await onSubmitLogout();
  };

  return (
    <Grid>
      <MenuItem css={textBox} onClick={onClickTicket}>
        <Grid css={textStyle}>{props.ticketText}</Grid>
      </MenuItem>
      <MenuItem css={textBox} onClick={onClickMailaddress}>
        <Grid css={textStyle}>{props.mailaddressText}</Grid>
      </MenuItem>
      <MenuItem css={textBox} onClick={onClickPassword}>
        <Grid css={textStyle}>{props.passwordText}</Grid>
      </MenuItem>
      <MenuItem css={textBox} onClick={onClickLogout}>
        <Grid css={textStyle}>{props.logoutText}</Grid>
      </MenuItem>
    </Grid>
  );
};

const textStyle = css`
  color: ${appColor.Text.primary};
  font-family: ${appTypo.font.kintoSans};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
  letter-spacing: 0.7px;
`;

const textBox = css`
  width: 240px;
  display: flex;
  height: 62px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid ${appColor.Border.grayLight};
  background: ${appColor.Background.white};
  cursor: pointer;
  @media (max-width: 767px) {
    /* 767px以下の場合に適用されるスタイル */
    width: auto;
  }
`;
