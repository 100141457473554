import Head from "next/head";
import { ReactNode } from "react";

import usePageView from "@/custom-hooks/google-analytics/usePageView";

import Footer from "./Footer/Footer";
import Header from "./Header/Header";

import { GoogleAnalytics } from "@/components/utils/GoogleAnalytics";

type Props = {
  pageTitle: string;
  children: ReactNode;
  description?: string;
};

export const BaseLayout = (props: Props) => {
  usePageView();

  return (
    <>
      <Head>
        <title>{`${props.pageTitle} - Go ATAMI`}</title>
        <meta name="description" content={props.description} />
      </Head>
      <GoogleAnalytics />
      <Header />
      {props.children}
      <Footer />
    </>
  );
};
